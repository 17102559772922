import React from "react";
import { Outlet, NavLink} from "react-router-dom";
import { Link, Image, Spacer, Container, Navbar, styled } from "@nextui-org/react";
import logo from './white-transparent.svg';

export default function Layout() {

  let inactiveStyle = {
    color: "inherit",
  };

  const routes = [
    {
      path: "/",
      name: "ABOUT",
    },
    {
      path: "/team",
      name: "TEAM",
    },
    {
      path: "/careers",
      name: "CAREERS",
    }
  ]

  function glow(e: any) {
    e.target.style.filter = "drop-shadow(0px 0px 20px white)";
  }
  function noglow(e: any) {
    e.target.style.filter = "";
  }

  return (
    <Container>
      <Navbar variant="static">
        <Navbar.Toggle showIn="xs" />
        <Navbar.Brand css={{ "@xs": { w: "12%", }, }}>
          <NavLink to="/">
            <Image
              onMouseEnter={glow}
              onMouseLeave={noglow}
              src={logo}
              alt="logo"
              height={75} />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs">
          {routes.map((route) => (
            <NavLink to={route.path} style={({ isActive }) =>
            isActive ? undefined : inactiveStyle
            }>{route.name}</NavLink>
          ))}
        </Navbar.Content>
        <Navbar.Collapse>
          <Navbar.CollapseItem>
            <Link color="inherit" css={{ minWidth: "100%" }} href="/">ABOUT</Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link color="inherit" css={{ minWidth: "100%" }} href="/team">TEAM</Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link color="inherit" css={{ minWidth: "100%" }} href="/careers">CAREERS</Link>
          </Navbar.CollapseItem>
        </Navbar.Collapse>
      </Navbar>
      <Spacer y={3} />
      <Outlet />
    </Container >
  )
}
