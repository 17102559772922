import React from "react";
import { Grid, Spacer, Container, Row, Col, Text } from "@nextui-org/react";
import { MailSignup } from "./MailSignup";
import './App.css';

export default function App() {

  return (
    <Grid.Container justify="center">
      <Grid xs={12} md={8}>
        <Text size="$xl" css={{fontWeight: "300"}}>
          <Text h1 css={{fontWeight: "700", fontFamily: "$serif", letterSpacing: "$normal", fontSize: "$4xl"}}>
            We're <Text b css={{ fontWeight: "600", textGradient: "45deg, $blue600 -20%, $pink600 50%"}}>Fixie.ai</Text>.
          </Text>
          <Spacer y={1} />
          We're setting out to change the way the world builds software,
          using AI as a foundation.
          <Spacer y={1} />
          We're founded by a <a href="/team">team</a> from Google and Apple with expertise
          in AI, systems, and the web. We're funded by Zetta Venture Partners, SignalFire,
          Bloomberg Beta, and others.
          <Spacer y={1} />
          We're <a href="/careers">hiring</a> for multiple roles.
          <Spacer y={1} />
          Check us out on <a href="https://www.linkedin.com/company/fixie-ai">LinkedIn</a>.
          <Spacer y={2} />
        </Text>
      </Grid>
      <Grid xs={12} md={8} justify="center">
        <MailSignup />
      </Grid>
    </Grid.Container>
  )
}
