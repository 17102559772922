import React, { useEffect, useState } from "react";
import { Row, Button, Card, Container, Grid, Spacer, Text } from "@nextui-org/react";

function JobCard({ job }: { job: any }) {
    const [jobDescription, setJobDescription] = useState("");

    return (
        <Grid xs={12} md={8}>
            <Card variant="bordered" borderWeight="bold" css={{ backgroundColor: "#101010" }} >
                <Card.Header>
                    <Grid.Container gap={0}>
                        <Grid xs={12}>
                            <Text h3>
                                {job.title}
                            </Text>
                        </Grid>
                        <Grid xs={12}>
                            <Text h6>
                                {job.location.name}
                            </Text>
                        </Grid>
                        <Grid xs={12}>
                            <Text>
                                {jobDescription}
                            </Text>
                        </Grid>
                    </Grid.Container>
                </Card.Header>
                <Card.Footer>
                    <Grid.Container gap={0}>
                        <Grid xs={12} justify="flex-end">
                            <Button auto onClick={ () => window.open(job.absolute_url, "_blank") }>Apply now</Button>
                        </Grid>
                    </Grid.Container>
                </Card.Footer>
            </Card>
        </Grid >
    );
}

export default function Greenhouse() {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetch("https://boards-api.greenhouse.io/v1/boards/fixieai/jobs")
            .then((response) => response.json())
            .then((data) => {
                setJobs(data.jobs);
            });
    }, []);

    const jobCards = jobs.map((job) => {
        <JobCard job={job} />;
    });

    return (
        <Container>
            <Spacer y={3} />
            <Grid.Container justify="center" gap={1}>
                {jobs.map((job) => { return <JobCard job={job} />; })}
            </Grid.Container>
        </Container>
    );
}