import React from "react";
import { Image, Grid, Link, Card, Spacer, Container, Row, Col, Text } from "@nextui-org/react";
import matt from './MattWelsh.jpg';
import justin from './JustinUberti.jpg';
import hessam from './HessamBagherinezhad.jpg';
import zach from './ZachKoch.jpg';

type PersonProps = {
  name: string;
  title: string;
  bio: string;
  image: string;
  linkedin?: string;
  twitter?: string;
  github?: string;
};

function Person(props: PersonProps) {
  return (
    <Card variant="bordered" borderWeight="bold" css={{ backgroundColor: "#101010" }} >
      <Card.Header>
        {/* <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}> */}
        <Grid.Container gap={1}>
          <Grid sm={12} md={5}>
            <Image src={props.image} alt={props.name} />
          </Grid>
          <Grid>
            <Text h6 weight="bold" transform="uppercase">
              {props.title}
            </Text>
            <Text h3>
              {props.name}
            </Text>
            <Text h6>
              {props.linkedin &&
                <Link href={props.linkedin} target="_blank">LinkedIn</Link>
              }
              {props.github &&
                <Link href={props.github} target="_blank">GitHub</Link>
              }
              {props.twitter &&
                <Link href={props.twitter} target="_blank">Twitter</Link>
              }
            </Text>
          </Grid>
        </Grid.Container>
      </Card.Header>
      <Card.Body>
        <Text size="$lg">
          {props.bio}
        </Text>
      </Card.Body>
    </Card>
  );
};

export default function Team() {

  return (
    <Container>
      <Grid.Container justify="center">
        <Grid xs={12} md={8}>
          <Text h1 css={{fontWeight: "700", fontFamily: "$serif", letterSpacing: "$normal", fontSize: "$4xl"}}>
            The <Text b css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%" }}>Fixie.ai</Text> team
          </Text>
          <Spacer y={2} />
        </Grid>
      </Grid.Container>
      <Grid.Container gap={1} justify="center" css={{ width: "100%" }}>
        <Grid xs={12} md={5}>
          <Person
            name="Matt Welsh"
            title="CEO"
            bio="Former SVP of Engineering at OctoML, Engineering Director at Google, Head of Platform at Xnor.ai, AI/ML Lead at Apple, and Professor at Harvard."
            image={matt}
            linkedin="https://www.linkedin.com/in/welsh-matt/"
            twitter="https://twitter.com/mdwelsh"
            github="https://github.com/mdwelsh"
          />
        </Grid>
        <Grid xs={12} md={5}>
          <Person
            name="Zach Koch"
            title="Chief Product Officer"
            bio="Former Director of Product at Shopify, Head of Product for Android and PM for Chrome at Google, and Engineer at several startups."
            image={zach}
            linkedin="https://www.linkedin.com/in/zachkoch/"
            twitter="https://twitter.com/zachk"
          />
        </Grid>
        <Grid xs={12} md={5}>
          <Person
            name="Hessam Bagherinezhad"
            title="Chief AI Officer"
            bio="Former AI/ML lead at Apple, Head of ML at Xnor.ai, PhD from University of Washington."
            image={hessam}
            linkedin="https://www.linkedin.com/in/hessam-bagherinezhad-86b09677/"
            github="https://github.com/hessamb"
          />
        </Grid>
        <Grid xs={12} md={5}>
          <Person
            name="Justin Uberti"
            title="Chief Technology Officer"
            bio="Former Distinguished Engineer at Google and Clubhouse, technical lead for Google Stadia, Duo, and WebRTC."
            image={justin}
            linkedin="https://www.linkedin.com/in/juberti/"
            twitter="https://twitter.com/juberti"
            github="https://github.com/juberti"
          />
        </Grid>
      </Grid.Container>
    </Container >
  )
}
