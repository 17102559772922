import React, { useState, MouseEvent } from "react";
import { Grid, NormalColors, Input, Card, Button, Spacer, Container, Row, Col, Text, Textarea } from "@nextui-org/react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url = "https://fixie.us21.list-manage.com/subscribe/post?u=9e5fca95adc9a75dce83d764e&amp;id=66f4d9b3d9&amp;f_id=004bc2e1f0";

const CustomForm = ({ status, message, onValidated }: { status: any, message: any, onValidated: any }) => {
  let email: any;
  const submit = () => {
    if (email) { // && email.value.indexOf("@") > -1) {
      onValidated({
        EMAIL: email.value,
      });
    }
  };

  let label: string = "";
  let color: NormalColors = "default" as const;
  if (status === "sending") {
    label = "Sending...";
    color = "primary";
  } else if (status === "error") {
    label = message;
    color = "error";
  } else if (status === "success") {
    label = message;
    color = "success";
  }

  return (
    <Grid.Container gap={1} justify="center" xs={8}>
      <Grid xs={12} justify="center">
        <Text h5>
          Let's keep in touch!
        </Text>
      </Grid>
      <Grid xs={12} justify="center">
        <Input
          bordered
          placeholder="Your email"
          label={label}
          color={color}
          onChange={(e) => email = e.target}
        />
      </Grid>
      <Grid xs={12} justify="center">
        <Button auto ghost color="gradient" size="sm" onClick={submit}>Submit</Button>
      </Grid>
    </Grid.Container>
  );
};

export function MailSignup() {

  const onValidated = (subscribe: any, formdata: any) => {
    subscribe(formdata);
  };

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData: any) => onValidated(subscribe, formData)}
        />
      )}
    />
  );
}
