import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createTheme, NextUIProvider } from '@nextui-org/react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Layout from './Layout';
import App from './App';
import Careers from './Careers';
import Team from './Team';

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {
      text: '#eee',
      gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
    },
    space: {},
    fonts: {
      sans: "Synonym",
      serif: "Amulya",
      mono: "Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono'"
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />
      },
      {
        path: "/team",
        element: <Team />
      },
      {
        path: "/careers",
        element: <Careers />
      },
    ]
  }]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <NextUIProvider theme={darkTheme}>
      <RouterProvider router={router} />
    </NextUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
