import React, { useEffect } from "react";
import { Grid, Spacer, Container, Row, Col, Text } from "@nextui-org/react";
import Iframe from "react-iframe";
import Greenhouse from "./Greenhouse";

export default function Careers() {
  return (
    <Grid.Container justify="center">
      <Grid xs={12} md={8}>
        <Text size="$xl" css={{ fontWeight: "300" }}>
          <Text
            h1
            css={{
              fontWeight: "700",
              fontFamily: "$serif",
              letterSpacing: "$normal",
              fontSize: "$4xl",
            }}
          >
            Careers at{" "}
            <Text
              b
              css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%" }}
            >
              Fixie.ai
            </Text>
          </Text>
          <Spacer y={1} />
          <Text>
            We're looking to hire creative, passionate people to help us build
            the future of software. Check out our job openings below.
          </Text>
          <Spacer y={1} />
          <Text>
            Fixie.ai is using AI to rethink how the world builds software
            systems. The building blocks of computing are rapidly shifting from
            complex, brittle, hand-coded services to flexible, general-purpose
            AI models that, given appropriate instructions, can perform a wide
            range of tasks.
          </Text>
          <Spacer y={1} />
          <Text>
            We envision a future in which systems, composed of AI-powered
            agents, interact with people as well as each other using natural
            language and rich media. From this vision, we are building a new
            computing platform that aims to usher in a new era of software.
          </Text>
          <Spacer y={1} />
          <Text>
            We are looking for Founding Engineers to join our team. We're based
            in Seattle.
          </Text>
          <Spacer y={1} />
          <Text>
            You should be:
            <ul style={{ listStyleType: "disc" }}>
              <li>An incredible coder</li>
              <li>Able to roll up your sleeves and get things done</li>
              <li>Able to deal with ambiguity</li>
              <li>Ready to move fast</li>
            </ul>
          </Text>
        </Text>
      </Grid>
      <Grid xs={12} md={9} justify="center">
        <Greenhouse />
      </Grid>
    </Grid.Container>
  );
}
